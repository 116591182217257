import React from "react";

const Profileicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 18 22"
      width="18"
      height="22"
    >
      <title>Layer 28</title>
      <defs>
        <image
          width="18"
          height="22"
          id="img28"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAWCAMAAAD6gTxzAAAAAXNSR0IB2cksfwAAAR1QTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////WLXmJAAAAF90Uk5TAFHV/7sxlsuCi+NldoczBqPhQRhgB1s4Eit4A/bMvD+VDNIKRP7Iz/kaKVJ9x79sVC6QWVqYjSSPzZolXZsReh+Jyo4jZx381MTk7JH7gGt3ZufF/Ue0SEpQq03x81eK5gmhAAAA1ElEQVR4nFWQ11ICQRQFz9IqCIoiBsyIgpgVMAdUzASzmP//M9x1doHph7ldXTU1t0b6xwlBV7c66CEc6Y0Sa5c+HG/0Ew/KAINGookgDZE0MsyIn0bxZYyUb3HGjUwwGdycmjaTmdaLs6Tdcy7DfHuLBbK5RfJLrbC8Iq2urW9Im1umFICiJyVXtj3ZIba7B/thODg84lg6oez207PceeXClUuuFEEW1xXdhOx0e6dq2k41VG/Y6R7xYKdH9JSx0/OLXnlzmu8+zY9P74O/vung51d/TdkVqRKW60cAAAAASUVORK5CYII="
        />
      </defs>
      <style></style>
      <use id="Layer 28" href="#img28" x="0" y="0" />
    </svg>
  );
};

export default Profileicon;
