import React from "react";

const Filesicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 20 16"
      width="20"
      height="16"
    >
      <title>Layer 36</title>
      <defs>
        <image
          width="20"
          height="16"
          id="img36"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAMAAAAhxq8pAAAAAXNSR0IB2cksfwAAAO1QTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////7abglwAAAE90Uk5Tyvr9/vZVAP+LUFHu0tPRoBI1C2V6hzRAAzdFKhcWKYY8IR1/9/js8/v0/NuTExQJEHXpbnnXuN+i4rbCxOCh+Up8CFTrMJ0+DT2j6vHyn/WuXEIAAACmSURBVHicXc3VEoJQFIXhbZ1lgZ1gI3ZhF3bH+z+OMsbA+S+/vWY2kc3usDtdzBzB7fF6fPALn8RA0MDQ+xSORL/FEDcwkUylJflbJotcvkAolqCUfymKWqnWqJ5qNFv/2q1OtwfS+qpkec0GFRqOxlZjkykJ9RmH8wVpiHK41GmFNYe1DQGy1bbYEfbcMIsD4cjhCWeCfjmZu1zBSLzdH+buzid7AfQ1HimORuEpAAAAAElFTkSuQmCC"
        />
      </defs>
      <style></style>
      <use id="Layer 36" href="#img36" x="0" y="0" />
    </svg>
  );
};

export default Filesicon;
