import React, { useState, useMemo, useEffect } from "react";
import { Form, Button, Container, Modal } from "react-bootstrap";
import { createNotification } from "../../helper/notification";
import { BiEdit, BiPlus } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import Loader from "../../helper/loader";
import * as inventoryService from "../../services/inventoryServices";
import MaterialReactTable from "material-react-table";
import InventoryBreadcurm from "./productInventorybreadcurm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./productInventory.scss";
import noIMage from "../../assets/img/userdetail/no-image.png";

const imgUrl = process.env.REACT_APP_IMAGE_URL 
const ProductInventory = () => {
  const [deleteAllShow, setDeleteAllShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const [page, setPage] = useState(1);

  const [loader, setLoader] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [inventoryData, setinventoryData] = useState([]);

  const reduxToken = useSelector((state) => state?.auth?.token);
  useEffect(() => {
    getInventory();
  }, [reduxToken]);
  const handleEditClick = (row) => {
    const id = row?.row?.original?._id;
    navigate(`/editInventory/${id}`);
  };

  const handleClose = () => {
    setShowModal(false);
    setDeleteAllShow(false);
  };

  const inputChange = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    setVal(value);
    const response = await inventoryService.searchInventoryByName(
      page,
      10,
      value,
      reduxToken
    );
    setinventoryData(response?.data?.data);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  const handleDelete = async () => {
    let id = deleteId;

    if (id) {
      const response = await inventoryService.removeInventory(id);
      if (response.status == 200) {
        createNotification("success", response.data.message);
        handleClose();

        getInventory();
        setVal("");
      }
    } else {
      removeInventory();
    }
  };

  const getInventory = async () => {
    setIsLoading(true);
    if (reduxToken) {
      const response = await inventoryService.inventoryList(reduxToken);
      if (response?.status == 200) {
        setinventoryData(response?.data?.data[0]?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const deleteAll = () => {
    setDeleteAllShow(true);
  };

  const removeInventory = async () => {
    const val = Object.keys(rowSelection);
    setLoader(true);
    const response = await inventoryService.removeMultiInventory(
      val,
      reduxToken
    );
    setRowSelection({});
    if (response.status == 200) {
      createNotification("success", response.data.message);
      getInventory();
      setVal("");
      setDeleteAllShow(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.productimg,
        id: "productImg",
        header: "Image",
        Cell: ({ row }) => (
          <div className="Product-img">
            {row?.original.productimg ? (
              <img
                src={`${imgUrl}/${row?.original.productimg}`}
                alt="product-img"
              />
            ) : (
              <img src={`${noIMage}`} alt="product-img" />
            )}
          </div>
        ),

        size: 100,
      },
      {
        accessorFn: (row) => row.name,
        id: "name",
        header: "Name",
        size: 180,
      },
      {
        accessorFn: (row) => `$${row.price}`,
        id: "price",
        header: "Price",
        size: 150,
      },
      {
        accessorFn: (row) => row.productstock,
        id: "productstock",
        header: "Product in stock",
      },

      {
        accessorFn: (row, index) => {
          let serviceLength = row?.service?.length;
          let servicess = row?.service?.map((val) => {
            return val?.service;
          });
          let servicesString = servicess.join(serviceLength > 1 ? " , " : " ");
          if (serviceLength > 1) {
            servicesString = servicesString.replace(/,(\s+)?$/, "");
          }
          return servicesString;
        },
        id: "service",
        header: "Service",
      },

      {
        accessorFn: (row) => row.action,
        id: "action",
        header: "Action",
        Cell: ({ cell }) => (
          <div className="action-btn">
            <Button
              onClick={() => {
                handleEditClick(cell);
              }}
            >
              <BiEdit />
            </Button>
            <Button onClick={() => handleDeleteClick(cell)}>
              <RiDeleteBinLine />
            </Button>
          </div>
        ),
      },
    ],
    [inventoryData]
  );

  const handleDeleteClick = (row) => {
    setDeleteId(row?.row?.original?._id);
    setRowSelection({});
    setShowModal(true);
  };

  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {}, [rowSelection]);
  const handleClick = () => {
    navigate("/AddInventory");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="dashboard-wrapper ds-layout-wrapper">
        <Container>
          <div className="ds-wrapper">
            <InventoryBreadcurm />
            <div className="layout-content-wrapper booking-layout">
              <div className="main-heading">
                <h1>Products Inventory</h1>
                <p>Below are the list of items</p>
              </div>
              <div className="userlist_wrapper">
                <div className="userlist-layout">
                  <div className="product-inventory-item">
                    <div className="prin-item-field">
                      <div className="item-stored">
                        <p># Items Stored</p>
                        <h1>{inventoryData && inventoryData.length }</h1>
                      </div>
                      <div className="prin-btn">
                        <Button onClick={handleClick}>
                          <BiPlus />
                          Add New Inventory
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="user-table">
                    <div className="user-tableheading">
                      <div className="entrie-field"></div>
                      <div className="user-search">
                        <Form className="d-block d-md-flex" onSubmit={handleFormSubmit}>
                          <label>Search</label>
                          <Form.Control
                            type="search"
                            placeholder="Search"
                            className="me-2"
                            onChange={inputChange}
                            aria-label="Search"
                            value={val}
                          />
                        </Form>
                      </div>
                    </div>

                    <div className="rvn-table-wrap product-inventory-list">
                      {Object.keys(rowSelection).length >= 1 ? (
                        <button className="boooking-remove" onClick={deleteAll}>
                          delete
                        </button>
                      ) : (
                        ""
                      )}

                      {isLoading ? (
                        <Loader />
                      ) : (
                        <MaterialReactTable
                          columns={columns}
                          pageSize={20}
                          getRowId={(row) => row._id}
                          data={inventoryData}
                          enablePagination={true}
                          enableRowSelection
                          onRowSelectionChange={setRowSelection}
                          state={{ rowSelection }}
                          enableColumnActions={false}
                          enableSorting={false}
                          enableTopToolbar={false}
                          enableColumnOrdering={false}
                          positionActionsColumn="last"
                        />
                      )}
                      <Modal
                        show={
                          showModal
                            ? showModal
                            : deleteAllShow
                            ? deleteAllShow
                            : ""
                        }
                      >
                        <Modal.Header>
                          <Modal.Title>Delete Inventory</Modal.Title>
                        </Modal.Header>
                        {deleteAllShow ? (
                          <Modal.Body>
                            Do you want to Delete All Inventory ?
                          </Modal.Body>
                        ) : showModal ? (
                          <Modal.Body>
                            Do you want to Delete this Inventory ?
                          </Modal.Body>
                        ) : (
                          ""
                        )}
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="danger" onClick={handleDelete}>
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default ProductInventory;
