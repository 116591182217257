import React, { useState, useMemo, useEffect, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { BsQuestionLg, BsCalendar4Event } from "react-icons/bs";
import { BiCalendar, BiPlus } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import * as getCompanyGoalservice from "../../services/goalCompanyBudgetService";
import UserBreadcurm from "./userBreadcurm";
import Userlist from "./userList";
const UserLayout = (props) => {
  let { id } = useParams();
  let [goalResult, setGoalResult] = useState("");
const Id = localStorage.front_user_id
  let getGoalById = async () => {
    const response = await getCompanyGoalservice.GoalsById(Id);
    if (response.data.data) {
      response.data &&
        response.data.data.map((val) => {
          setGoalResult(val.accurateGoals);
        });
    }
 
  };

  useEffect(() => {
    getGoalById();
  }, []);
  return (
    <div className="dashboard-wrapper ds-layout-wrapper">
      <Container>
        <div className="ds-wrapper">
          <UserBreadcurm />
          <div className="layout-content-wrapper booking-layout">
            <div className="main-heading">
              <h1>Your Client Database</h1>
              <p>Listed below are all the clients who have booked service or made purchases with you.</p>
            </div>
            <div className="goal-data-value-box">
              <Row>
                <Col xs={12} md={6} xl={4} className="mb-3 mb-xl-0">
                  <div className="data-value-box weekly">
                    <div className="goal-datavalue ">
                      <span className="icon">
                        <BsCalendar4Event />
                      </span>
                      <div className="value-detail">
                      <h1>
                      {goalResult?.weeklyGoals
                        ? goalResult?.weeklyGoals
                        : 0}
                    </h1>
                        <h2>Weekly Goals</h2>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} xl={4} className="mb-3 mb-xl-0">
                  <div className="data-value-box Monthly">
                    <div className="goal-datavalue ">
                      <span className="icon">
                        <FaCalendarAlt />
                      </span>
                      <div className="value-detail">
                      <h1>
                      {goalResult?.monthlyGoals
                        ? goalResult?.monthlyGoals
                        : 0}
                    </h1>
                        <h2>Monthly Goals</h2>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} xl={4} className="mb-3 mb-xl-0">
                  <div className="data-value-box Yearly">
                    <div className="goal-datavalue">
                      <span className="icon">
                        <BiCalendar />
                      </span>
                      <div className="value-detail">
                      <h1>
                      {goalResult?.monthlyGoals * 12
                        ? goalResult?.monthlyGoals * 12
                        : 0}
                    </h1>
                     
                        <h2>Yearly Goals</h2>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <Userlist />
          </div>
        </div>
      </Container>
    </div>
  );
};
export default UserLayout;
