import React from "react";

const Producticon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 21 18"
      width="21"
      height="18"
    >
      <title>Layer 31</title>
      <defs>
        <image
          width="21"
          height="18"
          id="img31"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAASCAMAAACDzGUcAAAAAXNSR0IB2cksfwAAAMBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////is+prwAAAEB0Uk5TAND/2ANmpZOgePTs4f0+LBxNvo6EgIGDi8v6+faiDcb30peV6cyIncXt05HC6ydyKt/PtL+J5DaC4wECfOLcyN+CECoAAACcSURBVHicdZDXDoJAEEWvY1kFKzZE7F2xi738/185zIOBJZ6Hk5uT7G6yABIUJZkCkKaMCpOlHFfDRJR8AShSSatlqsCianCuVmc1msG0qQXDIZ22C+p0A3p9ljmQPSTQSC6zXNZ4InvKdSZr7rAWS9mrX/XWm+1uf9DqUd45adU/+5frTWn1Ln5oNcz/asfqk+tL6bwJXuwbiD5fLH0N0BuTcIkAAAAASUVORK5CYII="
        />
      </defs>
      <style></style>
      <use id="Layer 31" href="#img31" x="0" y="0" />
    </svg>
  );
};

export default Producticon;
