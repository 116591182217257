import React, { useState, useRef, forwardRef, useEffect } from "react";
import {Dropdown, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ColorSettingicon from "../../assets/img/Colorsettingicon.png";
import ColorSettinglist from "./colorSettingsList";
import listPlugin from "@fullcalendar/list";
import { Button ,Modal} from "react-bootstrap";
import Eventoption from "./eventOption";
import * as bookingService from "../../services/bookingServices";
import { MdClose } from "react-icons/md";

const CalenderApp = () => {
  const calendarRef = useRef();
  const ref = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [showColor, setShowColor] = useState(false);
  const [showEvent, setShowEvent] = useState(false);
  const [data, setData] = useState([]);
  const [services, setServices] = useState([]);
  const [info, setInfo] = useState({});
  const [height, setHeight] = useState(0);
  const [idmodal, setIdModal] = useState(0);
  const [filterData, setFilterData] = useState(null);

  const handlemodalcls = () =>{
    setShowEvent(false)
  }
  const getDetails = async () => {
    const resp = await bookingService.bookingList();
    if (resp.status == 200) {
      const response = resp?.data?.data[0]?.data;
      let arr = [];
      if (response.length > 0) {
        response.map((res) => {
          let obj = {
            title: res?.service?.map((serv) => serv?.service),
            groupId: res?._id,
            start: moment(res?.startDateTime, 'ddd MMM DD YYYY hh:mm:a:SS [GMT]ZZ').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            end: moment(res?.startDateTime, 'ddd MMM DD YYYY hh:mm:a:SS [GMT]ZZ').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            borderColor: res?.eventColor,
            color: res?.eventColor,
            name:res?.name
          };
          arr.push(obj);
        });
      }
      setData(arr);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);


  const Colortoggle = () => {
    setShowColor(!showColor);
  };
  const ColorClose = () => {
    setShowColor(false);
  };
  const [dateselect, setDateSelect] = useState(false);
  const ExampleCustomInput = forwardRef(({ onClick }, ref) => (
    <button className="sdate-btn" onClick={onClick} ref={ref}>
      Select Date
    </button>
  ));

  useEffect(() => {
    setHeight(ref.current.clientHeight - 500);
  });

 

    const getServices = async () => {
      const resp = await bookingService.getServices();
      const response = resp?.data && resp?.data?.data &&   resp?.data?.data.length > 0 && resp?.data?.data[0]?.service;
      if (resp.status == 200) {
        setServices(response);
      }
    };

    useEffect(() => {
      getServices();
    }, []);

  const handleChange = async (e) => {
    let data = { service: e.target.value };
    const resp = await bookingService.filterBooking(data);

    if (resp.status == 200) {
      const response = resp?.data?.data;
      let arr = [];
      if (response.length > 0) {
        response.map((res) => {
          let obj = {
            title: res?.service?.map((serv) => serv.service),
            groupId: res._id,
            start: moment(res?.startDateTime, 'ddd MMM DD YYYY hh:mm:a:SS [GMT]ZZ').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            end: moment(res?.startDateTime, 'ddd MMM DD YYYY hh:mm:a:SS [GMT]ZZ').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            color: res.eventColor,
            name:res?.name

          };
          arr.push(obj);
        });
      }
      setData(arr);
    }
  };
  const handleEventClick = ({ event }) => {
    let id = event?._def?.defId;
    if (Number(idmodal) == Number(id)) {
      setShowEvent(false);
      setIdModal(0);
    } else {
      setShowEvent(true);
      setInfo(event);
      setIdModal(id);
    }
    const filteredData = data.filter((d) => d.groupId == event?._def?.groupId);
    setFilterData(filteredData);
  };

  const handleHideColorSetting = () => {
    setShowColor(false);
  };
  
  return (
    <div className="full-calendar-layout">
      <div className="flc-layout-wrap" ref={ref}>
        <div className="calendar-selectdate">
          <DatePicker
            peekNextMonth
            showYearDropdown
            showMonthDropdown
            todayButton="Today"
            selected={startDate}
            dropdownMode="select"
            customInput={<ExampleCustomInput />}
            dateFormat="yyyy/MM/dd"
            onChange={(date) => {
              setStartDate(date);
              calendarRef.current.getApi().gotoDate(new Date(date));
            }}
          />
        </div>
        <div className="fl-profile-filed">
          <div className="profile-field">
          </div>
          <div className="fl-sels-field">
            <Form.Select
              className="form-select"
              name="services"
              onChange={handleChange}
            >
              <option value="">Select Service</option>
              {services?.length > 0 &&
                services.map((service, i) => (
                  <option key={i} value={service?.serviceId?._id}>
                    {service?.serviceId?.service}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>
        <div className="fcl-btn ">
          <div className="fl-btn">
            <Button onClick={Colortoggle}>
              <img src={ColorSettingicon} alt="icon" />
            </Button>
            <ColorSettinglist isVisible={showColor} onHide={handleHideColorSetting} />
          </div>
         
        </div>
        <FullCalendar
          ref={calendarRef}
          initialView="timeGridWeek"
          hideOnOutsideClick={true}
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            listPlugin,
          ]}
          editable={false}
          headerToolbar={{
            right: "",
            center: "prev,title,next",
            left: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
          }}
          eventClick={handleEventClick}
          events={data}
          goToDate={startDate}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          }}
        />
        <Modal show={showEvent} onHide={handlemodalcls} className="eventlist-modal">
        <Modal.Header>
         <div className="event-heading">
          <Button className="clsbtn" onClick={handlemodalcls}><MdClose/></Button>
         </div>
        </Modal.Header>
        <Modal.Body>
        <Eventoption
            info={info}
            getDetails={getDetails}
            data={data}
            setShowEvent={setShowEvent}
            setIdModal={setIdModal}
            filterData={filterData}
          />
        </Modal.Body>
      </Modal>
      </div>
    </div>
  );
};
export default CalenderApp;