
import React, {useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createNotification } from "../../helper/notification";

const apiUrl = process.env.REACT_APP_API_BASE_URL

const CheckoutForm = ({ userId, service, paymentData, handlePyment, selectedBenificialCountry, selectedCountry, phone, benificialPhone, paymentType }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const cardElementStyle = {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#2c1b4c",
      },
    },
    invalid: {
      color: "#000",
    },
  };

  async function submitHandler(event) {
    event.preventDefault();
    const res = await stripe.createToken(elements.getElement(CardElement));
    setIsLoading(true)
    const token = res?.token?.id;
    const cardId = res?.token?.card?.id;
    const month = res?.token?.card?.exp_month;
    const year = res?.token?.card?.exp_year;
    const last4Digits = res?.token?.card?.last4;

    try {
      const response = await axios.post(`${apiUrl}/frontend/user/addCard`, {
        tokenCard: token,
        userId: userId,
        cardId: cardId,
        month: month,
        year: year,
        email: paymentData?.email,
        last4Digits: last4Digits,
      });


      if (response.status == "200") {

        const bookingPayment = await axios.post(
          `${apiUrl}/frontend/user/bookingPayment`,
          {
            paymentType: paymentType,
            selectedBenificialCountry:selectedBenificialCountry,
            selectedCountry:selectedCountry,
            phone:phone,
            benificialPhone:benificialPhone,
            userId: userId,
            service: service.map(item => item._id),
            name: paymentData.name,
            email: paymentData?.email,
            paymentMethodId: response?.data?.paymentMethodId,
            customerId: response?.data?.customerId,
          }
        );

        if (
          bookingPayment.status == 200 &&
          bookingPayment?.data?.clientSecret
        ) {

          if (bookingPayment?.data?.success == true) {

             handlePyment(bookingPayment?.data?.price);
            navigate("/payment-success");


          } else {
            navigate("/payment-failed");
          }
        }
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      createNotification("warning", "Card details are invalid");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="mb-3" style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "4px", backgroundColor: "transparent" }}>
        <CardElement options={{ style: cardElementStyle }} />
      </div>
      <button style={{ color: "#fff", backgroundColor: "#005941", border: "0", padding: "7px 20px", borderRadius: "4px" }} disabled={isLoading}  >{isLoading ? "Paying...." : "Pay"}</button>
    </form>
  );
};

export default CheckoutForm;
