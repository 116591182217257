import React from "react";

const Notesicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 18 21"
      width="18"
      height="21"
    >
      <title>Layer 33</title>
      <defs>
        <image
          width="18"
          height="21"
          id="img33"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAVCAMAAAB8FU7dAAAAAXNSR0IB2cksfwAAAGBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////BmKR8wAAACB0Uk5TJND/0SbhlpPgAJWRmDTe4zU5OglESEkPRpKUj+gu3zAR63i6AAAAb0lEQVR4nJ3QyQ6AIAxF0fpUQFSc5+n//1KMLtrEjd7lCSVNiQKIwohiKM1SMASdiCwIqSTtSUnKHspd4XMlo8pcZGpGL4Mv1LSdr20YdffmPaNhvF6Nw+fvf606SZo9LZJS0ArLr2qx0ubk7ffjBLIWDSGHCYlfAAAAAElFTkSuQmCC"
        />
      </defs>
      <style></style>
      <use id="Layer 33" href="#img33" x="0" y="0" />
    </svg>
  );
};

export default Notesicon;
