import React from "react";

const Invoiceicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 18 25"
      width="18"
      height="25"
    >
      <title>Layer 40</title>
      <defs>
        <image
          width="18"
          height="25"
          id="img40"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAZCAMAAAAL146mAAAAAXNSR0IB2cksfwAAAO1QTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////7abglwAAAE90Uk5Twf/J1ZPQbABhg3qqsy6CE0L7KdpXQwoyDRv25QIjFhn84yL1LAZMEoiSR9TWUE3e4eDfVXxzeFlnb3fdJTFAHmbqEO6vnJEX8Ljky+uo8lg0DEYAAAC3SURBVHicdc/VDoIxDAXgHX6guLu7u7u7vf/jsIQE0gHn8lvbtUJAiU5A07MYIGAkFpMkMyfLh6w2leyAw8nIBbjhYeTF1ywbfP4AJ6dBLhlUfgyFI1FGsXgimUozysi+bI5RvgAU1e1L5a+DftyoUKVa02RqWv1NjWarLdPqdP829jj1X1WDIdFoTFSQzxOIKZJdYDYHFktglXCvxWYLlHbA/gAIB5AKCzqezkQXL9H1RnR/xOkJzsMWMpWvlSQAAAAASUVORK5CYII="
        />
      </defs>
      <style></style>
      <use id="Layer 40" href="#img40" x="0" y="0" />
    </svg>
  );
};

export default Invoiceicon;
