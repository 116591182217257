import React from "react";

const Soapicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 22 20"
      width="22"
      height="20"
    >
      <title>Layer 34</title>
      <defs>
        <image
          width="22"
          height="20"
          id="img34"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAMAAAC+oj0CAAAAAXNSR0IB2cksfwAAAWJQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+cz50QAAAHZ0Uk5TAFXp/+A0mYldLlheXNDF8KxUIvyo2NR+GRsauNP5hq1rm8RihJU/tp4VaRLCCAkFMEH4LCSO+01SUUva7u1AX1YzMlc+HujlYP1h+rQMEAZwNkIrOK6vsKkN99djzM3OxnRvEb4Hj0Pq9lMOTkx3eHJoaj3JoDYXjLkAAAEWSURBVHicZZBlVwJBFIavvvYCJsquhaCLxQomYCBiIZi4Bopd2Pn/nQI5x+fDjefMmXtniAQVlaiqpn/UoBZ15aK+QdM0h9NFjU0OVjW3SN0KQRu5ZdEuNTo8um50dlF3j6Hrnl4o7eWxDz74edFf1AM8mgHn4BAvhjEi9WhpetAaoxDCUo+LNCEHThKmRD+NkMgzkWg0EiOanRPtPL98Ib6YWGIkksYyVrheXYNFLhRZJwS4Tm2kM7QZVFO3tmkHfCXs7iFL+/aBbduHR8fM5E64PiV3msL5M8b5BT+YxSXTV3SNm7/dby26gykef1+aiNwDUAAIj+zM07PiJY/Xt3d/kpD58H7GTMXXt/oqXwrlFH6E/QV3eynpEgGL2gAAAABJRU5ErkJggg=="
        />
      </defs>
      <style></style>
      <use id="Layer 34" href="#img34" x="0" y="0" />
    </svg>
  );
};

export default Soapicon;
