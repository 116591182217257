import React from "react";

const Appointmenticon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 21 20"
      width="21"
      height="20"
    >
      <title>Layer 29</title>
      <defs>
        <image
          width="21"
          height="20"
          id="img29"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAMAAABVlYYBAAAAAXNSR0IB2cksfwAAANtQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////ZlEDkgAAAEl0Uk5TAAzWCM8wBZXF2P/ZzOOnHMDn8vPs+N1qA8knRHYLEQkODVrx+bR1dKRdOhObFlfUiTdGeUMEv9zePZhuLUBoY51WXzzTVbVJ9oQbVesAAAC9SURBVHicZZDHVgJBEEUfSGgfsVUk2QxKUsKQk8RBgv//RTYMeIbpu6hwF1WnCtAEgg+4EgpfUiQqxCNjwiXOhBDJFNKU8onP0uWFGSn5CmaBXOg2APkCUCT4ppQqWcrFKr8r9UFUaFJBtVb30DiHzyr4BT9NPbfu6Vvtjo62z3bZMm2PfRh2wCHu7GgMlDjBveUUmBE+O+f3gku/xYpcw2MbbrnZ/m91tN0Zt/1ou7cvOAf7xpE4/Zo/y/4BtqIYLuDyq6IAAAAASUVORK5CYII="
        />
      </defs>
      <style></style>
      <use id="Layer 29" href="#img29" x="0" y="0" />
    </svg>
  );
};

export default Appointmenticon;
