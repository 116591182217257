import React from "react";
const UserForms = () => {
  return (
    <>
      <div className="user-file-wrapper">
        <h1>User Form</h1>
      </div>
    </>
  );
};
export default UserForms;
