import React from "react";
import CustomerForm from "../components/customerBooking/customerform";

const CustomerBooking = () => {
  return (
    <>
      <CustomerForm />
    </>
  );
};
export default CustomerBooking;
