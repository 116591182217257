import React from "react";

const Gifticon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 20 20"
      width="20"
      height="20"
    >
      <title>Layer 37</title>
      <defs>
        <image
          width="20"
          height="20"
          id="img37"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAAXNSR0IB2cksfwAAAPZQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////SKX8DgAAAFJ0Uk5TACzl/+s0KePqNxq9UbjIUq8fj4Fn9p5h8zpJb0deccdiQEVQu3VKofzu3/3gVnr19IY28nAvutDx0c3dk8bc2cvYAW5g74R48PvtdGPFprD5VRuKL3IAAADbSURBVHicvZDbUgIxEERbGkEFERFkRWHXRYHEC+h6wwuKAoIKrv//M87EfeALPA8zp7qSVFcAYSXF9CqQyXJtHQkbzOU3CbCwVeR2EpYkAHfKuiq7SVj1ZOyRNVnpfRcd1Bv0g/whGTaDIx7XW0CbHWN5QtpTyrKmwyJ45s6fd4HehdMmwUtngbwbXTm9Jm7sbUq46wP3kVr/wZcu9lF4GgDPL2pDvgLmTe+MKtLxz4yM8UT1fbps/xfOGqplKfLxqfY10x+ch8KCYchvtVwsYc1QiD3Si9WiH/wCoJciDJtj2jEAAAAASUVORK5CYII="
        />
      </defs>
      <style></style>
      <use id="Layer 37" href="#img37" x="0" y="0" />
    </svg>
  );
};

export default Gifticon;
